import {HeaderTabs} from "../../../Layout/HeaderTabs";
import {
  Anchor,
  Box,
  Breadcrumbs, Button, ColorPicker,
  Container, Flex,
  Group,
  InputWrapper, Loader,
  NumberInput,
  Select,
  Text,
  TextInput,
  Title
} from "@mantine/core";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import RitchText from "../../Galeries/Create/RitchText";
import PDFView from "./PDFView";
import useLocalStorage from "use-local-storage";
import {Poster, usePoster, useSavePoster, useUpdatePoster} from "../List/usePosters";
import {notifications} from "@mantine/notifications";
import useMDebounce from "../../../../utils/useDebounce";
import EmptyState from "../../../Shared/EmptyState";
import PageHeader from "../../../Shared/PageHeader";

const EditPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const id = params.id

  const savePoster = useUpdatePoster()

  const poster = usePoster(id ?? "")

  const [item, setItem] = useState(poster?.data)

  const save = useMDebounce((poster: Poster) => {
    savePoster.mutate({poster})
  }, 3000)

  const saveValue = (key: string, value: string) => {
    if(item) {
      setItem({...item, [key]: value})

      save({...item, [key]: value})
    }
  }

  useEffect(() => {
    if(!item && poster?.data) setItem(poster?.data)
  }, [poster]);

  return (
    <>
      <HeaderTabs/>

      <Container size="md">
        <Breadcrumbs mb={10}>
          <Link to="/"><Anchor>Dashboard</Anchor></Link>
          <Link to="/generator/events"><Anchor>Generátor plakátů</Anchor></Link>
          <Link to={`/generator/events/${id}`}><Anchor>Plakát {id}</Anchor></Link>
        </Breadcrumbs>

        {
          item ? (
            <>
              <PageHeader
                title={`Plakát ${id} | ${item.name.replaceAll("<p>", " ").replaceAll("</p>", " ")}`}
                subTitle="Data se automaticky ukládají."
                btn={(
                  <Button mb="md" color={savePoster.isPending ? "gray" : "green"} onClick={savePoster.isPending ? () => {} : () => {
                    savePoster.mutate({poster: item, after: () => {
                        navigate("/generator/events")
                      }})
                  }}>{
                    savePoster.isPending ? "Ukládání" : "Uložit"
                  }</Button>
                )}
              />


              <InputWrapper label="Název akce" mb="md">
                <RitchText defaultValue={item.name} name="text" onUpdate={(text) => saveValue("name", text)} onlyTextarea/>
              </InputWrapper>

              <Flex w="100%" mb="md" gap="xs">
                <Select
                  label="Velikost nadpisu"
                  defaultValue={item.nameSize}
                  w="10rem"
                  data={['sm', "md", "lg", "xl", "xxl", "mega", "extra"]}
                  onChange={(value => saveValue("nameSize", value ?? ""))}
                />
                <InputWrapper label="Barva textu" mb="md">
                  <ColorPicker
                    format="hex"
                    defaultValue={item.nameColor}
                    onChange={(value) => saveValue("nameColor", value)}
                    swatches={[
                      "#FFCC00", "#EE8027", "#E53434", "#A0067D", "#5E2281", "#172983", "#007BC2", "#89BA17"
                    ]}
                  />
                </InputWrapper>
              </Flex>

              <InputWrapper label="Text" mb="md">
                <RitchText defaultValue={item.text} name="text" onUpdate={(text) => saveValue("text", text)}/>
              </InputWrapper>

              <Flex w="100%" mb="md" gap="xs">
                <Select
                  label="Pozadí"
                  defaultValue={item.bg}
                  w="10rem"
                  mb="md"
                  data={[
                    {value: "0", label: "Šátek"},
                    {value: "1", label: "Lilie mřížka"},
                    {value: "2", label: "Teepee kompas"},
                    {value: "3", label: "Turbánek"},
                    {value: "4", label: "Pozdravy"},
                    {value: "5", label: "Mix"},
                    {value: "6", label: "Lilie"},
                    {value: "7", label: "Mix 2"},
                    {value: "8", label: "Prázdné"},
                  ]}
                  onChange={(value => saveValue("bg", value ?? ""))}
                />
                <Select
                  label="Ilustrace"
                  defaultValue={item.images}
                  w="20rem"
                  mb="md"
                  searchable
                  data={[
                    {
                      group: "Jaro",
                      items: [
                        {value: "27", label: "Čarodějnice"},
                      ]
                    },
                    {
                      group: "Podzim",
                      items: [
                        {value: "1", label: "Halloween - dýně"},
                        {value: "2", label: "Halloween - duch, hrad"},
                        {value: "3", label: "Halloween - duch, dýně, hřbitov"},
                        {value: "14", label: "Tři jezy"},
                        {value: "33", label: "Halloween 4"},
                        {value: "34", label: "Halloween 5"},
                        {value: "35", label: "Halloween 6"},
                        {value: "36", label: "Halloween 7"},
                      ]
                    },
                    {
                      group: "Zima",
                      items: [
                        {value: "0", label: "Vánoční bazén"},
                        {value: "18", label: "Vánoce - lidi"},
                        {value: "19", label: "Vánoce - pařící santa"},
                        {value: "20", label: "Vánoce - sovičky"},
                        {value: "21", label: "Vánoce - domky"},
                        {value: "22", label: "Vánoce - lyže"},
                        {value: "23", label: "Vánoce - hračky"},
                        {value: "24", label: "Vánoce - Praha, běžky"},
                        {value: "28", label: "Vánoce - pozadí 1"},
                        {value: "29", label: "Vánoce - pozadí 2"},
                        {value: "30", label: "Vánoce - pozadí 3"},
                        {value: "42", label: "Mikuláš"},
                        {value: "50", label: "Zimní liška"},
                        {value: "52", label: "Sníh - mix"},
                        {value: "53", label: "Sníh - mix 2"},
                      ]
                    },

                    {
                      group: "Mix",
                      items: [
                        {value: "4", label: "Mix"},
                        {value: "5", label: "Město"},
                        {value: "10", label: "Věda"},
                        {value: "31", label: "Věda 2"},
                        {value: "13", label: "Zvířata - slepice"},
                        {value: "26", label: "Maňásci/Loutky"},
                        {value: "37", label: "Les"},
                        {value: "39", label: "Panáček na stromě"},
                        {value: "41", label: "Kola"},
                        {value: "43", label: "Hudba 1"},
                        {value: "44", label: "Hudba 2"},
                        {value: "45", label: "Párty"},
                        {value: "46", label: "Praha"},
                        {value: "47", label: "Relax"},
                        {value: "48", label: "Skákající skupina"},
                        {value: "49", label: "Pochod"},
                        {value: "51", label: "Běh"},
                        {value: "54", label: "Hrad 1"},
                        {value: "55", label: "Hrad 2"},
                      ]
                    },
                    {
                      group: "Camp ilustrace",
                      items: [
                        {value: "6", label: "Stan 1"},
                        {value: "7", label: "Stan 2"},
                        {value: "8", label: "Stan 3"},
                        {value: "9", label: "Stan žlutý"},
                        {value: "32", label: "Stan - lidi"},
                        {value: "40", label: "Tábořiště"},
                      ]
                    },
                    {
                      group: "Outdoor",
                      items: [
                        {value: "11", label: "Outdoor 1"},
                        {value: "12", label: "Outdoor 2"},
                        {value: "15", label: "Outdoor 3"},
                        {value: "16", label: "Outdoor 4"},
                        {value: "17", label: "Outdoor 5"},
                        {value: "25", label: "Outdoor 6"},
                        {value: "38", label: "Outdoor 7 - skála"},
                      ]
                    },
                    {
                      group: "Speciální",
                      items: [
                        {value: "imaginarium", label: "Imaginárium"}
                      ]
                    }
                  ]}
                  onChange={(value => saveValue("images", value ?? ""))}
                />
              </Flex>


              <PDFView item={item}/>
            </>
          ) : null
        }

        <EmptyState isEmpty={!poster.data} isLoading={poster.isLoading} is404={true}/>
      </Container>
    </>
  )
}

export default EditPage
